/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Table } from 'antd';
import Text from '@ingka/text';
import Button from '@ingka/button';
import IcReplace from '@ingka/ssr-icon/paths/document-upload';
import './ChildItemsTable.css';
import { ChildItems } from '../ItemsImport/itemsImport';
import { useTranslate } from '../../../../common/services/translationService.js';
import { isNullEmptyUndefinedOrZero } from '../../../../common/services/commonService.js';

interface ChildItemsTableProps {
    data: ChildItems[];
    enableReplace: boolean;
    displayTitleBar: boolean;
    displayLabel: boolean;
    name?: string;
    disable?:boolean;
    onReplaceExcel?: () => void;
}

const ChildItemsTable = ({
  data, enableReplace, displayTitleBar,
  displayLabel, name, onReplaceExcel, disable,
}: ChildItemsTableProps) => {
  const tableTitle = useTranslate('customUnit.items.table.title');
  const currency = useTranslate('customUnit.items.table.currency');
  const btReplace = useTranslate('customUnit.items.table.btReplace');
  const tableLabel = useTranslate('customUnit.items.table.label');

  const totalPrice = () => {
    let total = 0;
    data?.forEach((item) => { total += item.totalPricePerQuantity; });
    return total;
  };

  const formatItemNo = (itemNo:string) => {
    const formattedItemNo = itemNo?.split('-')[1]?.replace(new RegExp(`.{${3}}`, 'g'), '$&.');
    return formattedItemNo;
  };

  const columns = [
    {
      title: 'PA',
      dataIndex: 'productAreaNo',
      key: 'PA',
      render: (value: number) => (value?.toLocaleString() || 'Not found'),
    },
    {
      title: 'Item #',
      dataIndex: 'itemNo',
      key: 'itemNumber',
      render: (value: number, record:any) => <div className="table-number itemNo-common-design">{value || formatItemNo(record.itemId)}</div>,
    },
    {
      title: 'Item type',
      dataIndex: 'itemType',
      key: 'itemType',
      render: (value: any) => (!isNullEmptyUndefinedOrZero(value) ? value?.toLocaleString() : 'Not found'),
    },
    {
      title: 'Item name',
      dataIndex: 'itemName',
      key: 'itemName',
      render: (value: any) => (value || 'Not found'),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (value: number) => value?.toLocaleString(),
    },
    {
      title: 'RSP',
      dataIndex: 'recommendedSellingPrice',
      key: 'RSP',
      render: (value: number) => (!isNullEmptyUndefinedOrZero(value) ? value?.toLocaleString() : 'Not found'),
    },
    {
      title: 'Total',
      dataIndex: 'totalPricePerQuantity',
      key: 'total',
      render: (value: number) => (!isNullEmptyUndefinedOrZero(value) ? value?.toLocaleString() : 'Not found'),
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      {displayTitleBar === true ? (
        <div className="citTitleWrapper">
          <div className="citTitleLeftWrapper">
            <Text className="citTitle">{`${name || 'News'}, ${tableTitle}`}</Text>
            <Text className="citTitleTwo">{ `${totalPrice()} ${currency}`}</Text>
            {/* <Text className="citSubTitle">{tableSubTitle}</Text> */}
          </div>
          {enableReplace === true ? (
            <Button
              text={btReplace}
              ssrIcon={IcReplace}
              small
              disabled={disable}
              onClick={onReplaceExcel}
              type="secondary"
            />
          ) : null}
        </div>
      ) : null}
      {displayLabel === true ? (
        <Text className={`citTableCount ${displayTitleBar === true ? 'citMt' : ''}`}>{`${tableLabel} (${data.length})`}</Text>
      ) : null}
      <Table
        className="citTalbe table-striped-rows"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
};

ChildItemsTable.defaultProps = {
  name: '',
  disable: false,
  onReplaceExcel: undefined,
};

export default ChildItemsTable;
