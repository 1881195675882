/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Carousel from '@ingka/carousel';
import Modal, { Theatre, ModalHeader, ModalBody } from '@ingka/modal';
import ZoomImage from '@ingka/zoom-image';
import './NewsImageModal.css';
import React from 'react';

interface NewsImageModalProps {
    imageList: any[],
    imageToken: any,
    openImageModal: boolean,
    onCloseImageModal: () => void,
    currentImageIndex?: number;
}

const NewsImageModal = ({
  imageList, imageToken, openImageModal, onCloseImageModal, currentImageIndex,
}: NewsImageModalProps) => {
  const carouselProps = {
    id: 'fcItemDetailsCarousel',
    hasNoControls: false,
    prefix: '',
    ariaLabelLeftBtn: 'See previous items',
    ariaLabelRightBtn: 'See next items',
    isSlideShow: true,
  };

  return (
    <Modal
      visible={openImageModal}
      handleCloseBtn={() => onCloseImageModal()}
    >
      <Theatre
        data-testid="image-details-theatre"
        aria-labelledby="fcnImageDetailsModal"
        className="nimImageDetails-theatre"
        header={(
          <ModalHeader
            className="defaultFont"
            titleId="fcnImageDetailsModal"
            title="Images"
            floating={false}
          />
          )}
      >
        <ModalBody>
          <div className="nimImgeContainer">
            <div className="nimIkeaImage">
              <Carousel {...carouselProps} slideIndex={currentImageIndex}>
                {imageList?.map(
                  (item:any) => (
                    <ZoomImage
                      src={item?.imagePath.concat(imageToken?.sasToken)}
                      data-testid={`ikea-image-${item?.imagePath}`}
                    >
                      <img
                        key={item?.imagePath}
                        className="nimItemDetails-item-image"
                        src={item?.imagePath.concat(imageToken?.sasToken)}
                        alt=""
                      />
                    </ZoomImage>
                  ),
                )}
              </Carousel>
            </div>
          </div>
        </ModalBody>
      </Theatre>
    </Modal>
  );
};

NewsImageModal.defaultProps = {
  currentImageIndex: 0,
};

export default NewsImageModal;
