/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the initial state using that type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: any = {
  unitResults: [],
};

export const Reducer = createSlice({
  name: 'findUnitReducer',
  initialState,
  reducers: {
    setUnitResults: (state, action: PayloadAction<any[]>) => {
      state.unitResults = [...action.payload];
    },
  },
});

export const {
  setUnitResults,
} = Reducer.actions;

export default Reducer.reducer;
