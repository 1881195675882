/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@ingka/button';
import Modal, {
  ModalBody, ModalHeader, Theatre,
} from '@ingka/modal';
import Carousel from '@ingka/carousel';
import React, { useEffect, useState } from 'react';
import plus from '@ingka/ssr-icon/paths/plus';
import Text from '@ingka/text';
import Accordion, { AccordionItem } from '@ingka/accordion';
import { useNavigate } from 'react-router-dom';
import './ItemDetails.css';
import { Divider } from 'antd';
import SSRIcon from '@ingka/ssr-icon';
import IcNotice from '@ingka/ssr-icon/paths/notice-small';
import IcArrowDownBase from '@ingka/ssr-icon/paths/arrow-down-to-base';
import jsdownload from 'js-file-download';
import { useTranslate } from '../../../../common/services/translationService.js';
import { appRoutes } from '../../../../common/constants/constant';
import {
  getFullUrl, isNullEmptyOrUndefined, isSu, userData, userRu,
} from '../../../../common/services/commonService.js';
import { setLoader } from '../../../../store/reducers/create-proj-reducer';
import useCallApi from '../../../../common/services/apiService.js';
import { useAppDispatch } from '../../../../store/hooks';
import BtiComponent from '../../../../common/components/btiComponent/BtiComponent';

interface ItemDetailsProps {
  openItemModal: boolean;
  projectId: any;
  selectedItem: any;
  isInstantComparison?: boolean;
  onCloseItemModal: () => void;
  selectedProj?: object;
  selectedItemData?:any;
}

const ItemDetails = ({
  openItemModal, projectId, selectedItem, isInstantComparison,
  onCloseItemModal, selectedProj, selectedItemData,
}: ItemDetailsProps) => {
  const basicData = selectedItem?.comparisonItemBasicViewEntity;
  const deadlineDate = basicData?.deadlineDate;
  const canAddComparison = basicData?.canAddComparison;
  const navigate = useNavigate();
  const { fileDownload } = useCallApi();
  const dispatch = useAppDispatch();
  const dimentionMetricText = useTranslate('itemDetails.dimensionMetric');
  const cuoItemNo = useTranslate('tab.items.item');
  const comments = useTranslate('customUnit.details.commentsLabel');
  const materialText = useTranslate('itemDetails.material');
  const dimensionImperialText = useTranslate('itemDetails.dimensionImperial');
  const cuoHfb = useTranslate('customUnit.overview.hfb');
  const cuoPa = useTranslate('customUnit.overview.pa');
  const cuoPrice = useTranslate('customUnit.overview.price');
  const cuoReferenceUrl = useTranslate('customUnit.overview.referenceUrl');
  const cuoInfoText = useTranslate('customUnit.infoText');
  const idBtDownload = useTranslate('itemDetails.btDownload');
  const [imageToken, setImageToken] = useState<any>();
  const { get } = useCallApi();

  const carouselProps = {
    id: 'ItemDetailsCarousel',
    hasNoControls: false,
    prefix: '',
    ariaLabelLeftBtn: 'See previous items',
    ariaLabelRightBtn: 'See next items',
    isSlideShow: true,
  };

  const renderAccordionItems = (title: string, data: string, type?: string) => (
    <div key={`${type}${title}${data}`} className={type ? 'flexColumn itemContWidth' : 'flexRow itemContWidth'}>
      {title && (
      <Text className="itemDetails-measure-title">
        {title}
        {title.includes(':') ? '\u00A0' : ':\u00A0'}
      </Text>
      )}
      <Text className="itemDetails-measure-value">{data}</Text>
    </div>
  );

  const renderTabItems = (title: string, data: string) => (
    <>
      <div className="cuoSection">
        <Text className="cuoLabel">{title}</Text>
        <Text className="cuoValue">{data}</Text>
      </div>
      <Divider type="vertical" />
    </>
  );

  const getSasToken = () => {
    get('get-sas', setImageToken);
  };

  useEffect(() => {
    getSasToken();
  }, []);

  const downloadChildItems = () => {
    dispatch(setLoader(true));
    const payload = {
      CustomUnitId: basicData?.customUnitId,
      UserId: userData()?.userId || null,
      UserType: userData()?.isOfUserType,
      RetailUnitCode: isSu() ? localStorage.getItem('RuCodeForSu') : userRu(),
    };
    fileDownload('download-custom-unit-items', payload, (response: any) => {
      jsdownload(response, `${basicData?.itemNo?.replaceAll('.', '')}_Items.xlsx`);
      dispatch(setLoader(false));
    }, () => {
      dispatch(setLoader(false));
    });
  };

  return (
    <Modal
      visible={openItemModal}
      handleCloseBtn={() => onCloseItemModal()}
    >
      <Theatre
        aria-labelledby="itemDetailModal"
        className="itemDetails-theatre"
        header={(
          <ModalHeader
            className="defaultFont"
            titleId="itemDetailModal"
            title={useTranslate('inst.comp.itemDetailsTitle')}
            floating={false}
          />
          )}
      >
        <ModalBody>
          {selectedItem?.comparisonItemImageViewEntity?.length !== 0 ? (
            <Carousel {...carouselProps} isSlideShow={false}>
              {selectedItem?.comparisonItemImageViewEntity?.map(
                (item:any) => <img key={item?.imagePath} className="itemDetails-item-image" src={selectedItemData.itemType === 'CST' ? item?.imagePath.concat(imageToken?.sasToken) : item?.imagePath} alt="" />,
              )}
            </Carousel>
          ) : null}
          <div className="itemDetailsContentWrapper">
            <div className="leftContainerItemDetails">
              <Text className="itemDetails-item-title">{basicData?.itemName}</Text>
              {selectedItemData.itemType !== 'CST' ? (
                <>
                  <div className="itemDetails-item-subTitle-container">
                    <Text className="itemDetails-item-subTitle">
                      {`${selectedItem?.comparisonItemBasicViewEntity?.paNo}  •  ${selectedItem?.comparisonItemBasicViewEntity?.itemNo}${!isNullEmptyOrUndefined(selectedItem?.priceInclTax) ? `  •  ${selectedItem?.comparisonItemBasicViewEntity?.currency} ${selectedItem?.priceInclTax}` : ''}`}
                    </Text>
                    {selectedItem?.comparisonItemBasicViewEntity?.isBti === true ? (
                      <div style={{ display: 'flex', marginLeft: '8px', alignItems: 'center' }}>
                        {'  •'}
                        <BtiComponent ml />
                      </div>
                    ) : null}
                  </div>
                  <Accordion>
                    <AccordionItem
                      id="itemDetailsDimensionMetric"
                      title={dimentionMetricText}
                      open
                    >
                      <div className="materialWrapper">
                        {selectedItem?.metricMeasureList?.map(
                          (val: any) => renderAccordionItems(val.typeName, val.value, 'met'),
                        )}
                      </div>
                    </AccordionItem>
                  </Accordion>
                  <Accordion>
                    <AccordionItem
                      id="itemDetailsMaterial"
                      title={materialText}
                    >
                      {selectedItem?.materialMeasureList?.map(
                        (val: any) => renderAccordionItems(val.typeName, val.value, 'mat'),
                      )}
                    </AccordionItem>
                  </Accordion>
                  <Accordion>
                    <AccordionItem
                      id="itemDetailsDimensionImperial"
                      title={dimensionImperialText}
                    >
                      {selectedItem?.imperialMeasureList?.map(
                        (val: any) => renderAccordionItems(val.typeName, val.value),
                      )}
                    </AccordionItem>
                  </Accordion>
                </>
              )
                : (
                  <>
                    <div className="cuContainer">
                      <div className="itemDetailSectionWrapper idTopWrapper">
                        {renderTabItems(cuoItemNo, selectedItemData.itemNo)}
                        {renderTabItems(cuoPrice, !isNullEmptyOrUndefined(selectedItem?.priceInclTax) ? `${selectedItem?.priceInclTax} ${basicData?.currency}` : `${0} EUR`)}
                        {renderTabItems(cuoHfb, basicData?.hfbNo)}
                        {renderTabItems(cuoPa, basicData?.paNo)}
                        {selectedItem?.comparisonItemBasicViewEntity?.isBti === true ? (
                          <BtiComponent large />
                        ) : null}
                      </div>
                      <div className="noticeContainer">
                        <SSRIcon className="cuoNoticeIcon" paths={IcNotice} />
                        <Text className="noticeText">
                          {cuoInfoText}
                        </Text>
                      </div>
                      <div className="itemDetailSectionWrapper cuoMidWrapper">
                        {!isNullEmptyOrUndefined(selectedItemData?.piaFactsLink) ? (
                          <div className="cuoSection">
                            <Text className="cuoLabel">{cuoReferenceUrl}</Text>
                            <div className="cuoValue cuoUrl">
                              <a href={getFullUrl(selectedItemData?.piaFactsLink)} target="_blank" rel="noopener noreferrer">
                                {selectedItemData?.piaFactsLink}
                              </a>
                            </div>
                          </div>
                        )
                          : null}
                      </div>
                      <Button
                        className="idBtDownload"
                        text={idBtDownload}
                        ssrIcon={IcArrowDownBase}
                        onClick={downloadChildItems}
                        type="secondary"
                        small
                      />
                    </div>
                    <Accordion>
                      <AccordionItem
                        id="itemDetailsComments"
                        title={comments}
                        open
                      >
                        <Text className="itemDetails-measure-value">{basicData.comment ? basicData.comment : ''}</Text>
                      </AccordionItem>
                    </Accordion>
                  </>
                )}
            </div>
            <div className="rightContainer">
              {!isInstantComparison ? (
                <Button
                  className="btAddComparison"
                  text="Add Comparison"
                  type="emphasised"
                  small={false}
                  ssrIcon={plus}
                  fluid
                  disabled={canAddComparison === false}
                  onClick={() => {
                    navigate(
                      appRoutes.IC,
                      {
                        state: {
                          selectedProj,
                          isSend: true,
                          isInstantCompare: false,
                          projectItem: selectedItem,
                          projectId,
                        },
                      },
                    );
                    onCloseItemModal();
                  }}
                />
              ) : null}
              <Text className="tDeadline">Deadline</Text>
              <Text className="itemDetails-item-date">
                {deadlineDate}
              </Text>
            </div>
          </div>
        </ModalBody>
      </Theatre>
    </Modal>
  );
};

ItemDetails.defaultProps = {
  isInstantComparison: false,
  selectedProj: {},
  selectedItemData: {},
};

export default ItemDetails;
