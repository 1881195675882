/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import Text from '@ingka/text';
import Dragger from 'antd/lib/upload/Dragger';
import { UploadFile, UploadProps } from 'antd';
import Button from '@ingka/button';
import fileDelete from '@ingka/ssr-icon/paths/cross';
import './ItemsImport.css';
import InlineMessage from '@ingka/inline-message';
import { useLocation } from 'react-router-dom';
import { useTranslate } from '../../../../common/services/translationService.js';
import { setLoader, setToast } from '../../../../store/reducers/create-proj-reducer';
import IcImageSmall from '../../../../assets/images/ic_csv_stack_small.svg';
import IcImage from '../../../../assets/images/ic_csv_stack.svg';
import IcImageDisabled from '../../../../assets/images/ic_csv_stack_disabled.svg';
import { excelFileType } from '../../../../common/constants/constant';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  isNullEmptyOrUndefined, userData,
} from '../../../../common/services/commonService.js';
import useCallApi from '../../../../common/services/apiService.js';
import ChildItemsTable from '../childItemsTable/ChildItemsTable';
import CloseModal from '../../../../common/components/closeModal/CloseModal';
import { createUnit } from '../../../../store/reducers/create-unit-reducer';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/hyperlink/dist/style.css';
import '@ingka/inline-message/dist/style.css';
import '@ingka/focus/dist/style.css';

export interface ChildItems {
  recommendedSellingPrice: number;
  productAreaNo: string;
  isValid: boolean;
  itemNo: string;
  itemName: string;
  quantity: number;
  totalPricePerQuantity: number;
}

interface ErrorPanel {
  show: boolean;
  message?: string;
  title?: string;
}

const ItemsImport = () => {
  const { post } = useCallApi();
  const location: any = useLocation();
  const [rawItemList, setRawtemList] = useState<any[]>([]);
  const [itemList, setItemList] = useState<ChildItems[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [importDisable, setImportDisable] = useState<boolean>(false);
  const [openReplaceWarning, setOpenReplaceWarning] = useState<boolean>(false);
  const [showErrorPanel, setShowErrorPanel] = useState<ErrorPanel>({ show: false });
  const customUnitData = useAppSelector((state) => state?.customUnitReducer?.createUnitData);
  const { Name } = customUnitData?.detailsTab || {};
  const PhotoTextThree = useTranslate('inst.comp.PhotoTextThree');
  const csvDialogBtnPositive = useTranslate('csv.dialog.btnPositive');

  const warningTitle = useTranslate('customUnit.items.replaceExcel.warningTitle');
  const warningSubTitle = useTranslate('customUnit.items.replaceExcel.warningSubTitle');
  const actionPositive = useTranslate('common.text.continue');
  const actionNegetive = useTranslate('common.text.cancel');

  const fileUploadErr = useTranslate('fileUpload.format.error');
  const dialogTitle = useTranslate('csv.dialog.title');
  const tableLabel = useTranslate('customUnit.items.table.label');
  const tableFormat = useTranslate('customUnit.csv.format');

  const commonErr = useTranslate('customUnit.items.commonErr');
  const errTitle = useTranslate('customUnit.items.errTitle');
  const dispatch = useAppDispatch();
  const editPrjtData = location.state?.unitData;
  const editPrjtItems = editPrjtData?.itemsTab;
  const isEditPrjt = location.state?.isEdit || false;

  const findInvalidDataInExcel = (array: any[]) => {
    const invalidRow: any[] = [];
    const qtyRegex = /^[0123456789]+$/;
    array.forEach((item) => {
      if (item.length < 3) {
        invalidRow.push(item);
      } else if (!qtyRegex.test(item[2])) {
        invalidRow.push(item);
      }
    });
    return invalidRow;
  };

  useEffect(() => {
    if (isEditPrjt === true) {
      setItemList(editPrjtItems?.importedItems || []);
    }
  }, [editPrjtData]);

  const appendZeros = (array: any[]) => {
    let appendedArray: any[] = [];
    appendedArray = array.map((obj) => {
      if (obj) {
        const trimmedData = obj.shift();
        return [trimmedData.toString()?.replace(/\./g, '').padStart(8, '0'), ...obj];
      }
      return obj;
    });
    return appendedArray;
  };

  const validateXlsxData = (column: any, valuesArray:any) => {
    if (column?.length !== 0) {
      setImportDisable(false);
      const xlsxDataInValid = findInvalidDataInExcel(valuesArray);
      if (xlsxDataInValid.length === 0) {
        setImportDisable(false);
        setShowErrorPanel({
          show: false,
        });
      } else {
        setImportDisable(true);
        setShowErrorPanel({
          show: true,
          message: commonErr,
          title: errTitle,
        });
      }
    } else {
      setImportDisable(true);
      setShowErrorPanel({
        show: true,
        message: commonErr,
        title: errTitle,
      });
    }
  };

  const csvChangeHandler = (event: any) => {
    const { file } = event;
    const reader = new FileReader();
    reader.onload = (evt) => { // evt = on_file_select event
      const rowsArray: any[] = [];
      const valuesArray: any[] = [];
      const bstr = evt?.target?.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      /* Update state */
      data.forEach((d: any) => {
        rowsArray.push(Object.keys(d));
        valuesArray.push(Object.values(d));
      });
      validateXlsxData(rowsArray[0], valuesArray);
      setRawtemList(valuesArray);
    };
    reader.readAsBinaryString(file);
  };

  const onImportClick = () => {
    dispatch(setLoader(true));
    if (!isNullEmptyOrUndefined(rawItemList)) {
      const itemDetails: any[] = [];
      const addZeroItemList = appendZeros(rawItemList);
      addZeroItemList?.forEach((item) => {
        itemDetails.push({
          ItemNo: `${item[1]}-${item[0]}`,
          Quantity: item[2],
        });
      });
      const payload = {
        UserId: userData()?.userId || null,
        UserType: userData()?.isOfUserType,
        ItemDetails: itemDetails,
      };
      post('price-details', payload, (data: any) => {
        setItemList(data);
        const itemData = { ...customUnitData, itemsTab: { importedItems: data } };
        dispatch(createUnit(itemData));
        dispatch(setLoader(false));
      }, () => {
        // Handle error
        dispatch(setLoader(false));
      });
    }
  };

  const uploadProps: UploadProps = {
    name: 'XLSX',
    listType: 'picture',
    maxCount: 1,
    // className: `upload-list-inline ${fileList.length >= 1 ? 'disableUpload' : ''}`,
    multiple: false,
    accept: '.xlsx',
    itemRender: (originNode, file) => (
      <div className="csvUploadStatusWrapper">
        <div className="csvUploadStatusLeft">
          <div className="csvIconWrapper">
            <img className="icPhoto" src={IcImageSmall} alt="csvStack" />
          </div>
          <div className="csvstatusFileWrapper">
            <span className="csvStatusFileName">{file.name}</span>
          </div>
        </div>
        <div className="ecxlFlex">
          <Button
            className="btCsvImport"
            text={csvDialogBtnPositive}
            type="primary"
            disabled={fileList?.length === 0 || importDisable}
            onClick={onImportClick}
            small
          />
          <Button
            small
            type="tertiary"
            iconOnly
            ssrIcon={fileDelete}
            onClick={() => {
              setFileList([]);
              setShowErrorPanel({ show: false });
            }}
          />
        </div>
      </div>
    ),
    beforeUpload: (file) => {
      const updatedFile: any = file;
      updatedFile.thumbUrl = IcImage;
      const isFileSupported = excelFileType.includes(updatedFile.type);
      if (!isFileSupported) {
        dispatch(setToast({ openToast: true, toastLabel: fileUploadErr }));
      } else {
        setFileList(
          (fileLists) => [...fileLists, updatedFile],
        );
      }
      return false;
    },
    onRemove: () => {
      setFileList([]);
    },
    fileList,
    onChange: csvChangeHandler,
  };

  const onExcelReplace = () => {
    setItemList([]);
    setFileList([]);
    const itemData = { ...customUnitData, itemsTab: { importedItems: [] } };
    dispatch(createUnit(itemData));
  };

  return (
    <div className="udParent">
      <Text
        className="tabTitle"
        headingSize="m"
        tagName="h1"
      >
        {tableLabel}
      </Text>
      {itemList?.length <= 0 ? (
        <div className={`iimContentWrapper ${fileList?.length !== 0 ? 'itemImportWrapper' : 'itemDragWrapper'}`}>
          <Text
            className="itemsTabLabel"
            bodySize="m"
            tagName="p"
          >
            {dialogTitle}
          </Text>
          <Dragger {...uploadProps} disabled={fileList?.length !== 0}>
            <p className={`ant-upload-drag-icon ${fileList?.length !== 0 ? 'disableUpload' : ''}`}>
              {fileList?.length !== 0 ? (
                <img className="emptyDataIcon icPhoto" src={IcImageDisabled} alt="csvStackDisabled" />
              ) : (
                <img className="emptyDataIcon icPhoto" src={IcImage} alt="csvStack" />
              )}
            </p>
            <p className="ant-upload-text">{PhotoTextThree}</p>
          </Dragger>
          <Text
            className="itemsTabFormat"
            bodySize="m"
            tagName="p"
          >
            {tableFormat}
          </Text>
          {showErrorPanel && showErrorPanel?.show === true ? (
            <InlineMessage
              className="iimErrorMessage"
              body={showErrorPanel?.message}
              link=""
              linkLabel=""
              title={showErrorPanel?.title}
              variant="negative"
              dismissable
            />
          ) : null}
        </div>
      ) : (
        <ChildItemsTable
          data={itemList}
          displayLabel
          displayTitleBar
          enableReplace
          disable={isEditPrjt === true && editPrjtData?.generic?.canEdit === false}
          name={Name}
          onReplaceExcel={() => setOpenReplaceWarning(true)}
        />
      )}
      <CloseModal
        title={warningTitle}
        subText={warningSubTitle}
        modelOpen={openReplaceWarning}
        saveText={actionPositive}
        closeText={actionNegetive}
        saveValid
        closeModal={(type) => {
          if (type === 2) {
            onExcelReplace();
          }
          setOpenReplaceWarning(false);
        }}
      />
    </div>
  );
};
ItemsImport.defaultProps = {
};
export default ItemsImport;
