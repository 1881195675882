/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import './CustomUnitOverview.css';
import Modal, { ModalHeader, Theatre } from '@ingka/modal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  convertToBase64, projectType, userData,
} from '../../../../common/services/commonService.js';
import { offlineData } from '../../../../common/configs/offlineData';
import { useTranslate } from '../../../../common/services/translationService.js';
import useCallApi from '../../../../common/services/apiService.js';
import { appRoutes } from '../../../../common/constants/constant';
import { setLoader, setToast } from '../../../../store/reducers/create-proj-reducer';
import { createUnitFullReset } from '../../../../store/reducers/create-unit-reducer';
import CustomNews from '../customNews/CustomNews';

interface CustomUnitOverviewProps {
    open: boolean
    onClose: () => void
}

const CustomUnitOverview = ({
  open, onClose,
}:CustomUnitOverviewProps) => {
  const customUnitData = useAppSelector((state) => state?.customUnitReducer?.createUnitData);
  const imageChangeFlg = useAppSelector((state) => state?.customUnitReducer?.imgChangeFlag);
  const detailsTab = JSON.parse(JSON.stringify(customUnitData.detailsTab));
  const itemsTab = JSON.parse(JSON.stringify(customUnitData.itemsTab));
  const { post } = useCallApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location: any = useLocation();

  const cuoTitle = useTranslate('customUnit.overview.title');
  const cuoSuccessMsg = useTranslate('customUnit.overview.success');
  const cuoUpdateSuccessMsg = useTranslate('customUnit.overview.update.success');
  const cuoFailureMsg = useTranslate('customUnit.overview.failure');
  const editPrjtData = location.state?.unitData;
  const editPrjtDetails = editPrjtData?.detailsTab;
  const isEditPrjt = location.state?.isEdit || false;

  const totalPrice = () => {
    let total = 0;
    itemsTab?.importedItems?.forEach((item: any) => { total += item.totalPricePerQuantity; });
    return total;
  };

  const clearCachedData = () => {
    dispatch(createUnitFullReset());
  };

  const createBlobObj = (val:any, index: number) => {
    let blob;
    if (val instanceof Blob) {
      blob = val;
    } else {
      blob = new Blob([val.originFileObj], { type: val.type });
    }
    // Create a custom object that includes the Blob and additional properties
    return {
      blob,
      name: val.name || `default_name${index}`,
      lastModified: val.lastModified || Date.now(),
      uid: val.uid || `default_uid${index}`,
      type: val.type || `default_type${index}`,
    };
  };

  const saveUnit = async () => {
    dispatch(setLoader(true));
    const formData = new FormData();
    const {
      Hfb, Pra, Pa, unitState, roc, priceClass, Name, PiaLink, Comments,
    } = detailsTab || {};
    let imageList:any = [];
    if (isEditPrjt === false) {
      imageList = await Promise.all(
        detailsTab?.Images?.map(async (image: any, index: number) => {
          const val: any = await offlineData.getItem(image);
          return createBlobObj(val, index);
        }),
      );
    } else {
      imageList = await Promise.all(
        detailsTab?.Images?.map(async (image: any, index:number) => {
          if (typeof image !== 'string') {
            const existingimgblb = await fetch(image.url)
              .then((response) => response.blob())
              .then((blob) => ({
                blob,
              }));
            return existingimgblb;
          }
          const val: any = await offlineData.getItem(image);
          return createBlobObj(val, index);
        }),
      );
    }

    const { importedItems } = itemsTab || {};
    const itemsList: any[] = [];
    importedItems?.forEach((item: any) => {
      const itemBuild = {
        itemType: item?.itemType,
        itemNo: item?.itemNo,
        itemQty: item?.quantity,
      };
      itemsList.push(itemBuild);
    });
    const payload = {
      customUnitId: isEditPrjt === true ? editPrjtData?.generic.unitId : 0,
      customUnitName: Name?.trim(),
      hfbNo: Hfb,
      praNo: Pra,
      paNo: Pa,
      unitState: unitState?.value,
      roc: roc?.value,
      priceClass: priceClass?.value,
      userId: userData()?.userId,
      userType: projectType(),
      unitUrl: PiaLink,
      unitRsp: totalPrice(),
      unitCurrency: 'EUR',
      comment: Comments,
      customUnitItemsList: itemsList,
      // eslint-disable-next-line no-unneeded-ternary
      isImageUpdate: ((imageChangeFlg === true && isEditPrjt === true)
        || (imageList?.length !== 0 && isEditPrjt === false)) ? true : false,
      unitImages: (isEditPrjt === true && imageChangeFlg === false)
        ? editPrjtDetails?.ImageNames.toString() : null,
      customUnitNo: isEditPrjt === true ? editPrjtData?.generic.unitNo : '',
    };
    const payloadBase64 = convertToBase64(payload)?.toString() || '';
    formData.append('customunit', payloadBase64);
    if (isEditPrjt === false) {
      imageList.forEach((image: any) => {
        const lastIndex = image?.name?.lastIndexOf('.');
        const replaced = `IMAGE${image?.name?.substring(lastIndex)}`;
        formData.append('unitImages', image?.blob, replaced);
      });
    } else {
      const imgfullData:any = [];
      imageList.forEach((image: any, index:number) => {
        imgfullData.push({
          blob: image.blob,
          name: image.name || `default_name${index}.jpeg`,
          lastModified: image.lastModified || Date.now(),
          uid: image.uid || `default_uid${index}`,
          type: image.type || 'image/jpeg',
        });
      });
      imgfullData.forEach((image: any) => {
        const lastIndex = image?.name?.lastIndexOf('.');
        const replaced = `IMAGE${image?.name?.substring(lastIndex)}`;
        formData.append('unitImages', image?.blob, replaced);
      });
    }
    post('save-custom-unit', formData, (data: any) => {
      if (data?.isSuccess === true) {
        dispatch(setToast({
          openToast: true,
          toastLabel: isEditPrjt === true
            ? cuoUpdateSuccessMsg : cuoSuccessMsg,
        }));
        isEditPrjt === true ? navigate(
          appRoutes.viewUnit,
          {
            state: {
              isVuDetails: true,
            },
          },
        ) : navigate(appRoutes.dashboard);
        clearCachedData();
      } else {
        dispatch(setToast({ openToast: true, toastLabel: cuoFailureMsg }));
      }
      dispatch(setLoader(false));
    }, () => {
      dispatch(setToast({ openToast: true, toastLabel: cuoFailureMsg }));
      dispatch(setLoader(false));
    });
  };

  return (
    <Modal
      className="cuoParent"
      visible={open}
      focusLockProps={{
        disabled: true,
      }}
      handleCloseBtn={onClose}
    >
      <Theatre
        aria-label="Accessibility header for a modal"
        header={<ModalHeader ariaCloseTxt="Close button text" title={cuoTitle} />}
      >
        <CustomNews
          key={`preview#${detailsTab?.Name}`}
          detailsTab={detailsTab}
          itemsTab={itemsTab}
          isPreview
          isEditPrjt={isEditPrjt}
          saveUnit={saveUnit}
        />
      </Theatre>
    </Modal>
  );
};

export default CustomUnitOverview;
