/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/loading/dist/style.css';
import Button from '@ingka/button';
import Pill from '@ingka/pill';
import IcDownArrow from '@ingka/ssr-icon/paths/chevron-down';
import IcUpArrow from '@ingka/ssr-icon/paths/chevron-up';
import IcRightArrow from '@ingka/ssr-icon/paths/chevron-right-small';
import {
  TreeSelect,
  Space, TableProps,
} from 'antd';
import {
  SorterResult,
} from 'antd/lib/table/interface';
import SSRIcon from '@ingka/ssr-icon';
import SearchData from '@ingka/search';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import jsdownload from 'js-file-download';
import _ from 'lodash';
import { setLoader } from '../../../../store/reducers/create-proj-reducer';
import { useTranslate } from '../../../../common/services/translationService.js';
import useCallApi from '../../../../common/services/apiService.js';
import './Filters.css';
import styles from './Filters.module.css';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  projectType,
  userData, isNullEmptyOrUndefined, capitalizeFirstLetter,
} from '../../../../common/services/commonService.js';
import { offlineData } from '../../../../common/configs/offlineData';
import {
  CustomNews,
  UnitList,
} from '../unitList/UnitList';
import PillsToolbar from './pillsToolbar/PillsToolbar';
import PageHeader from '../../../../common/components/header/Header';
import CloseModal from '../../../../common/components/closeModal/CloseModal';
import { setUnitResults } from '../../../../store/reducers/find-unit-reducer';

export interface HFB {
  codeName: string,
  section: string,
  id: string,
  text: string,
  isExpanded: boolean,
  isChecked: boolean,
  items?: HFB[];
}

const { SHOW_PARENT } = TreeSelect;

export const HFBtree = ({
  projectData, isHFBOpened, dropdownClick, onCheckboxChange, defaultVal, customClass,
}: {
  projectData: HFB[],
  isHFBOpened: boolean,
  dropdownClick: (isOpen: boolean, type: string) => void,
  onCheckboxChange: (val: [], type: string) => void,
  defaultVal: [],
  customClass?: string,
}) => (
  <div className={`fcHfbFieldContainer ${customClass}`}>
    <TreeSelect
      treeCheckable
      treeData={projectData}
      showCheckedStrategy={SHOW_PARENT}
      fieldNames={{
        label: 'codeName',
        value: 'id',
        children: 'items',
      }}
      style={{
        width: '160px',
      }}
      showArrow
      value={defaultVal}
      suffixIcon={isHFBOpened ? <SSRIcon paths={IcUpArrow} /> : <SSRIcon paths={IcDownArrow} />}
      onDropdownVisibleChange={(val: any) => dropdownClick(val, 'hfb')}
      virtual={false}
      onChange={(val) => onCheckboxChange(val, 'hfb')}
      switcherIcon={<SSRIcon paths={IcRightArrow} />}
      popupClassName="fcHfbTree"
    />
    <span className="fcHfbPlaceholder">{useTranslate('tab.items.hfbPlaceholder')}</span>
  </div>
);

HFBtree.defaultProps = {
  customClass: '',
};

const Filters = () => {
  dayjs.extend(isSameOrBefore);
  const location: any = useLocation();

  const userDetails: any = {
    userType: projectType(),
    userId: null,
  };

  const unitSearchPayload: any = {
    hfbId: [],
    praId: [],
    paId: [],
    customUnitName: '',
    customUnitNo: '',
  };

  const searchData = { ...unitSearchPayload, ...userDetails };
  const [isHFBOpened, setIsHFBOpened] = useState(false);
  const [isMyCustomNews, setIsMyCustomNews] = useState<boolean>(false);
  const [HFBdata, setHFBdata] = useState<HFB[]>([]);
  const [triggerShowmore, setTriggerShowmore] = useState<boolean>(false);
  const [openProjectNotFoundModal, setOpenProjectNotFoundModal] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<CustomNews[]>([]);
  const [searchPayload, setSearchPayload] = useState(searchData);
  const [hasFilter, setHasFilter] = useState(false);
  const { post, get } = useCallApi();
  const didMountRef = useRef(false);
  const [selectedHFBs, setSelectedHFBs] = useState<[]>([]);
  const [selectedPRAs, setSelectedPRAs] = useState<[]>([]);
  const [selectedPAs, setSelectedPAs] = useState<[]>([]);
  const [selectedAllHFBs, setSelectedAllHFBs] = useState<[]>([]);

  const [isDownload, setIsDownload] = useState(false);
  const [itemNo, setItemNo] = useState<any>();
  const [searchText, setSearchText] = useState<any>();
  const [itemName, setItemName] = useState<any>();
  const dispatch = useAppDispatch();

  const getHFB = () => {
    // Fetch enumData from IndexedDB using offlineData
    offlineData.getItem('enumData').then((enumData: any) => {
      const hfbEntry = enumData?.find((item: any) => item.key === 'CUS_HFB_LIST');

      if (hfbEntry?.value) {
        const hfbIdsArray = hfbEntry.value.split('|');

        // Set filtered HFB data based on CUS_HFB_LIST
        get('business-structure', (businessStructureData: HFB[]) => {
          const filteredData = businessStructureData.filter((hfb) => hfbIdsArray.includes(hfb.id));
          setHFBdata(filteredData);
        });
      } else {
        // If no CUS_HFB_LIST found or the value is empty, fetch the full HFB data
        get('business-structure', setHFBdata);
      }
    });
  };

  useEffect(() => {
    getHFB();
  }, []);

  const toggleLoader = (showLoader: boolean) => {
    if (!triggerShowmore) {
      dispatch(setLoader(showLoader));
    } else {
      setIsLoading(showLoader);
    }
  };

  const setHFBKeys = (filters: any) => {
    if (!isNullEmptyOrUndefined(filters?.HfbNos)) {
      return filters?.HfbNos;
    }
    if (!isNullEmptyOrUndefined(filters?.PraNos)) {
      return filters?.PraNos;
    }
    if (!isNullEmptyOrUndefined(filters?.PaNos)) {
      return filters?.PaNos;
    }
    return [];
  };

  const setSearchInput = (val: any) => {
    const searchInput: any = document?.getElementById('fcSearch');
    if (searchInput) {
      searchInput.value = val;
    }
    const event = new Event('input', { bubbles: true });
    searchInput?.dispatchEvent(event);
  };

  useEffect(() => {
    if (location.state?.isVuDetails === true) {
      if (!isNullEmptyOrUndefined(localStorage.getItem('selectedFUFilters'))) {
        if (localStorage.getItem('selectedFUFilters')) {
          const filters = JSON.parse(localStorage.getItem('selectedFUFilters') as any);
          const customNewsChecked = !isNullEmptyOrUndefined(filters?.userId);
          // Page Filters
          setSelectedAllHFBs(setHFBKeys(filters));
          setIsMyCustomNews(customNewsChecked);
          //  Filters
          setSelectedHFBs(filters?.hfbId);
          setSelectedPRAs(filters?.praId);
          setSelectedPAs(filters?.paId);

          setItemNo(filters?.customUnitNo);
          setItemName(filters?.customUnitName);
          setSearchText(filters?.customUnitNo || filters?.customUnitName);
          !isNullEmptyOrUndefined(filters?.customUnitNo) && setSearchInput(filters?.customUnitNo);
          !isNullEmptyOrUndefined(filters?.customUnitName) && setSearchInput(filters?.customUnitName);
          setSearchPayload(filters);
        }
      }
    }
  }, []);

  const setFilterShow = (searchDat: any) => {
    const checkFilterExist = _.isEqual(searchDat, searchData);
    return !checkFilterExist;
  };

  useEffect(() => {
    if (didMountRef.current) {
      setHasFilter(setFilterShow(searchPayload));
      if (setFilterShow(searchPayload)) {
        toggleLoader(true);
        post(
          'search-custom-unit',
          searchPayload,
          (response: CustomNews[]) => {
            setSearchResults([...searchResults, ...response]);
            dispatch(setUnitResults(response));
            toggleLoader(false);
          },
          (error: any) => {
            setSearchResults([]);
            dispatch(setUnitResults([]));
            toggleLoader(false);
          },
        );
      } else {
        setSearchResults([]);
        dispatch(setUnitResults([]));
        toggleLoader(false);
      }
      setTriggerShowmore(false);
    }
    didMountRef.current = true;
  }, [searchPayload]);

  const handleTableChange: TableProps<CustomNews>['onChange'] = (pagination, filters, sorter, extra) => {
    const sortEntity = ((sorter as SorterResult<CustomNews>).field) as string;
    const sortTypeParam = (sorter as SorterResult<CustomNews>).order;
    const typeOfSort = (!sortTypeParam || sortTypeParam === 'ascend') ? 'asc' : 'desc';
    setSearchResults([]);
    const fcSortPayload = {
      ...searchPayload,
      SortEntity: capitalizeFirstLetter(sortEntity),
      TypeOfSort: typeOfSort,
      StartWith: 0,
    };
    setSearchPayload(searchPayload);
    localStorage.removeItem('selectedFUFilters');
    localStorage.setItem('selectedFUFilters', JSON.stringify(searchPayload));
  };

  const onSearchChange = (e: any, data: any) => {
    const searchTerm = data?.value || null;
    if (searchTerm === null) {
      setSearchResults([]);
      setItemNo(null);
      setItemName(null);
      setSearchText(null);
      localStorage.removeItem('selectedFUFilters');
      localStorage.setItem('selectedFUFilters', JSON.stringify(searchPayload));
    } else {
      const enteredInput = searchTerm.trim()
        .replace('.', '')
        .replace('.', '')
        .replace('-', '')
        .replace('-', '');

      if (isNaN(enteredInput) === false && enteredInput.length === 8) {
        setSearchText(searchTerm);
        setItemNo(searchTerm);
      } else {
        setSearchText(searchTerm);
        setItemName(searchTerm);
      }
      setSearchResults([]);
      localStorage.removeItem('selectedFUFilters');
      localStorage.setItem('selectedFUFilters', JSON.stringify(searchPayload));
    }
  };

  // Clear all Filters
  const clearAllFilter = () => {
    // page filter
    setSelectedAllHFBs([]);
    setIsMyCustomNews(false);
    setItemNo(null);
    setItemName(null);
    // sidebar fileters
    setSelectedHFBs([]);
    setSelectedPRAs([]);
    setSelectedPAs([]);
    setSearchResults([]);
    dispatch(setUnitResults([]));
    setIsDownload(false);
    setSearchInput('');
    setSearchText('');
    setSearchPayload(searchData);
  };

  const checkFilterPrerequistie = () => {
    if (
      !isNullEmptyOrUndefined(selectedHFBs)

      || !isNullEmptyOrUndefined(itemNo)
      || !isNullEmptyOrUndefined(itemName)
      || !(isMyCustomNews === false)
      || !isNullEmptyOrUndefined(selectedHFBs)
      || !isNullEmptyOrUndefined(selectedPRAs)
      || !isNullEmptyOrUndefined(selectedPAs)
    ) {
      return false;
    }
    return true;
  };

  // Adding this logic antd Tree component sending all child values to chips
  const removeCommon = (first: any, second: any) => {
    const combine = [...first, ...second];
    return combine.filter((el) => !(first.includes(el) && second.includes(el)));
  };

  const setFilterHFBValues = () => {
    const isHfbChecked = selectedHFBs.some((hfb) => selectedHFBs.includes(hfb));
    if (!isNullEmptyOrUndefined(selectedHFBs) && isHfbChecked) {
      return selectedHFBs;
    }
    return null;
  };

  const setFilterPRAValues = () => {
    const isHfbChecked = selectedHFBs.some((hfb) => selectedHFBs.includes(hfb));
    if (!isNullEmptyOrUndefined(selectedHFBs) && isHfbChecked) {
      const praFilterData: any = [];
      selectedHFBs.forEach((hfb: any) => {
        HFBdata.forEach((hfbList: any) => {
          hfbList.items.forEach((pra: any) => {
            if (hfb === hfbList.id) {
              praFilterData.push(pra.id);
            }
          });
        });
      });
      const isPraChecked = selectedPRAs.some((pra) => praFilterData.includes(pra));
      if (selectedPRAs.length === 0) {
        return selectedPRAs;
      }
      if (!isPraChecked) {
        return selectedPRAs;
      }
      const selectedPraNos: any = removeCommon(selectedPRAs, praFilterData);
      setSelectedPRAs(selectedPraNos);
      return selectedPraNos;
    }
    return selectedPRAs;
  };

  const setFilterPAValues = () => {
    const paFilterData: any = [];
    if (!isNullEmptyOrUndefined(selectedPRAs)) {
      selectedPRAs.forEach((pra: any) => {
        selectedPAs.forEach((pa: any) => {
          if (pa.slice(0, 3) === pra) {
            paFilterData.push(pa);
          }
        });
      });
      if (selectedPAs.length === 0) {
        return selectedPAs;
      }
      const selectedPaNos: any = removeCommon(selectedPAs, paFilterData);
      setSelectedPAs(selectedPaNos);
      return selectedPaNos;
    }
    if (!isNullEmptyOrUndefined(selectedHFBs)) {
      selectedHFBs.forEach((hfb: any) => {
        HFBdata.forEach((hfbList: any) => {
          hfbList.items.forEach((pra: any) => {
            pra.items.forEach((pa: any) => {
              if (hfb === hfbList.id) {
                paFilterData.push(pa.id);
              }
            });
          });
        });
      });
      if (selectedPAs.length === 0) {
        return selectedPAs;
      }
      const selectedPaNos: any = removeCommon(selectedPAs, paFilterData);
      setSelectedPAs(selectedPaNos);
      return selectedPaNos;
    }
    return selectedPAs;
  };

  // Building a payload for search unit
  const buildAllFilterPayload = (pageFilter: boolean) => {
    const payload = {
      hfbId: setFilterHFBValues() || [],
      praId: setFilterPRAValues() || [],
      paId: setFilterPAValues() || [],
      userId: isMyCustomNews === true ? userData()?.userId : null,
      userType: projectType(),
      customUnitName: itemName || '',
      customUnitNo: itemNo || '',
    };
    const fcPayload = pageFilter === true ? searchPayload : payload;
    setSearchPayload(fcPayload);
    localStorage.removeItem('selectedFUFilters');
    localStorage.setItem('selectedFUFilters', JSON.stringify(fcPayload));
  };

  const triggerFilterPayload = () => {
    setSearchResults([]);
    if (checkFilterPrerequistie()) {
      clearAllFilter();
    } else {
      buildAllFilterPayload(false);
    }
  };

  // Page Filter Selection payload
  const onCheckboxChange = (selection: any, type: string) => {
    let HFBData: any = [];
    let PRAData: any = [];
    let PAData: any = [];
    let isCustomUnit: any;

    switch (type) {
      case 'hfb':
        setSelectedAllHFBs(selection);
        if (selection.length !== 0) {
          selection?.map((val: string) => {
            switch (val.length) {
              case 2:
                HFBData.push(val);
                setSelectedHFBs(HFBData);
                break;
              case 3:
                PRAData.push(val);
                setSelectedPRAs(PRAData);
                break;
              case 4:
                PAData.push(val);
                setSelectedPAs(PAData);
                break;
              default:
                break;
            }
            return null;
          });
        } else {
          HFBData = []; PRAData = []; PAData = [];
          setSelectedAllHFBs([]);
          setSelectedHFBs([]);
          setSelectedPRAs([]);
          setSelectedPAs([]);
        }
        break;
      case 'myCustomNews':
        isCustomUnit = selection;
        setIsMyCustomNews(isCustomUnit);
        break;
      default:
        break;
    }
    buildAllFilterPayload(true);
  };

  const dropdownClick = (isOpen: boolean, type: string) => {
    switch (type) {
      case 'hfb':
        setIsHFBOpened(isOpen);
        break;
      default:
        break;
    }
  };

  const myCustomNewsHandler = () => {
    if (isMyCustomNews === false) {
      setIsMyCustomNews(true);
      onCheckboxChange(true, 'myCustomNews');
    } else {
      setIsMyCustomNews(false);
      onCheckboxChange(false, 'myCustomNews');
    }
  };

  useEffect(() => {
    const fcTable = document.querySelector<HTMLElement>('.ant-table-body');
    if (fcTable) {
      fcTable.addEventListener('scroll', () => {
        // eslint-disable-next-line max-len
        const scrollPercentage = (fcTable.scrollTop / (fcTable.scrollHeight - fcTable.clientHeight)) * 100;
        if (scrollPercentage <= 99) {
          (document.getElementsByClassName('paginationWrapper') as HTMLCollectionOf<HTMLElement>)[0].style.display = 'none';
        }
        if (scrollPercentage >= 99) {
          (document.getElementsByClassName('paginationWrapper') as HTMLCollectionOf<HTMLElement>)[0].style.display = '';
        }
      });
    }
  }, []);

  const getHeaderHeight = () => {
    const height = document.getElementById('fcHeaderWrapperDiv')?.offsetHeight || 0;
    return height + 148;
  };

  return (
    <div>
      <PageHeader
        isEnable={isDownload}
        title={useTranslate('customUnit.find.title')}
        buttonLabel=""
      />
      <div className="fcContentWrapper">
        <div className="fcHeaderWrapper" id="fcHeaderWrapperDiv">
          <div className="flexbox">
            <Space className="filters-container">
              {HFBdata.length > 0 && (
                <HFBtree
                  projectData={HFBdata}
                  isHFBOpened={isHFBOpened}
                  dropdownClick={dropdownClick}
                  onCheckboxChange={onCheckboxChange}
                  defaultVal={selectedAllHFBs}
                />
              )}
              <Pill
                iconPosition="trailing"
                label={useTranslate('customUnit.pill.mycustomnews')}
                small
                selected={isMyCustomNews}
                onClick={myCustomNewsHandler}
                className="fcTitle"
              />
            </Space>
            <SearchData
              className="search_pill_fc"
              id="fcSearch"
              placeholder={useTranslate('customUnit.search.placeholder')}
              onSearch={onSearchChange}
              value={searchText}
              onChange={(e, data) => onSearchChange(e, data)}
              onClear={(e, data) => {
                onSearchChange(e, null);
                clearAllFilter();
              }}
            />
            <Button
              className="fcSearchBtn"
              type="primary"
              text="Search"
              small
              onClick={triggerFilterPayload}
            />
          </div>
          <hr className="fc-demo-divider__hr-horizontal" />
          {hasFilter === true ? (
            <PillsToolbar
              data={searchPayload}
              hfbData={selectedAllHFBs}
              totalUnits={searchResults?.length}
              setMyCustomNews={(isMyCustomNews: any) => {
                setIsMyCustomNews(isMyCustomNews);
              }}
              onRemoveFilter={(newPayload, newHfbData) => {
                const HFBData: any = [];
                const PRAData: any = [];
                const PAData: any = [];
                setSelectedAllHFBs(newHfbData);
                newHfbData?.map((val: string) => {
                  switch (val.length) {
                    case 2:
                      HFBData.push(val);
                      setSelectedHFBs(HFBData);
                      break;
                    case 3:
                      PRAData.push(val);
                      setSelectedPRAs(PRAData);
                      break;
                    case 4:
                      PAData.push(val);
                      setSelectedPAs(PAData);
                      break;
                    default:
                      break;
                  }
                  return null;
                });
                setSearchResults([]);
                setIsDownload(false);
                setSearchPayload({ ...newPayload });
                setHasFilter(setFilterShow(newPayload));
                localStorage.setItem('selectedFUFilters', JSON.stringify({ ...newPayload }));
                if (JSON.stringify(newPayload) === JSON.stringify(searchData)) {
                  localStorage.setItem('selectedFUFilters', JSON.stringify(searchData));
                  clearAllFilter();
                }
              }}
              onClearAll={() => {
                localStorage.setItem('selectedFUFilters', JSON.stringify(searchData));
                clearAllFilter();
                setHasFilter(false);
              }}
            />
          ) : null}
        </div>
        <UnitList
          headerHeight={getHeaderHeight()}
          searchResults={searchResults}
          handleTableChange={handleTableChange}
        />
      </div>
      <CloseModal
        modelOpen={openProjectNotFoundModal}
        closeModal={() => {
          setOpenProjectNotFoundModal(false);
        }}
        saveValid
        closeText="Ok"
        saveText=""
        title={useTranslate('find.comp.compNotFound.title')}
        subText={useTranslate('find.comp.compNotFound.text')}
      />
    </div>
  );
};

export default Filters;
