/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable dot-notation */
/* eslint-disable @typescript-eslint/no-explicit-any */
import SSRIcon from '@ingka/ssr-icon';
import React, { useEffect, useState } from 'react';
import LeftArrow from '@ingka/ssr-icon/paths/chevron-left-small';
import RightArrow from '@ingka/ssr-icon/paths/chevron-right-small';
import Dots from '@ingka/ssr-icon/paths/ellipses-horizontal';
import Notice from '@ingka/ssr-icon/paths/notice-small';
import IcPencil from '@ingka/ssr-icon/paths/pencil';
import LinkOut from '@ingka/ssr-icon/paths/link-out';
import './SentAndClosedProjectDetails.css';
import Text from '@ingka/text';
import Button from '@ingka/button';
import jsdownload from 'js-file-download';
import {
  Table, Dropdown, MenuProps,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import styles from '../newReceived/ProjectDetails.module.css';
import cbUnchecked from '../../../../../assets/images/cbUnchecked.svg';
import cbChecked from '../../../../../assets/images/cbChecked.svg';
import cbUncheckedDisabled from '../../../../../assets/images/cbUncheckedDisabled.svg';
import { useTranslate } from '../../../../../common/services/translationService.js';
import { Item } from '../../../createProject/items/Items';
import {
  isIos, isRu, isSu, projectType, userData, isNullEmptyOrUndefined, convertToBase64,
} from '../../../../../common/services/commonService.js';
import { ProjectType } from '../../../dashboard/Dashboard';
import { appRoutes, projStatus } from '../../../../../common/constants/constant';
import { setLoader, setToast } from '../../../../../store/reducers/create-proj-reducer';
import useCallApi from '../../../../../common/services/apiService.js';
import { useAppDispatch } from '../../../../../store/hooks';
import { offlineData } from '../../../../../common/configs/offlineData';
import { RetailUnit } from '../../../createProject/retailUnitsTab/RetailUnits';
import ItemDetails from '../../itemDetails/ItemDetails';
import IncludedUnits from './IncludedUnits';
import CloseModal from '../../../../../common/components/closeModal/CloseModal';
import BtiComponent from '../../../../../common/components/btiComponent/BtiComponent';
import ConnectCompetitorPanel from './connectCompetitorPanel/ConnectCompetitorPanel';

const Header = ({
  isEdit, isDelete, isCopy, isExport, projectName, projectStatus,
  onBackPress, printProjectClick, onDeleteProject, onExportProject, onCopyProject,
  navigateToEditProject,
}: {
  isEdit: boolean,
  isDelete: boolean,
  isCopy: boolean,
  isExport: boolean,
  projectName: any,
  projectStatus: any,
  onBackPress: () => void,
  printProjectClick: () => void,
  onDeleteProject: () => void,
  onExportProject: () => void,
  navigateToEditProject: () => void,
  onCopyProject: () => void,
}) => {
  const items: MenuProps['items'] = [
    ...projectStatus && !(projectStatus === ProjectType.CLOSED)
      && !(isIos() && (projectStatus === ProjectType.ACCEPTED))
      && !(isIos() && (projectStatus === ProjectType.SENT))
      && !(isSu() && (projectStatus === ProjectType.ACCEPTED)) ? [
        {
          key: '1',
          label: (
            <Text onClick={printProjectClick}>
              Print Project
            </Text>
          ),
        }] : [],
    ...isDelete ? [{
      key: '2',
      label: (
        <Text onClick={onDeleteProject}>
          Delete Project
        </Text>
      ),
    }] : [],
    ...isCopy ? [{
      key: '3',
      label: (
        <Text onClick={onCopyProject}>
          Copy Project
        </Text>
      ),
    }] : [],
    ...isExport ? [{
      key: '4',
      label: (
        <Text onClick={onExportProject}>
          Export item list to Excel
        </Text>
      ),
    }] : [],
  ];

  return (
    <div className="scHeaderContainer">
      <div className="scLeftWrapper">
        <Button
          className="scBtTertiary"
          iconOnly
          small
          type="tertiary"
          onClick={onBackPress}
        >
          <SSRIcon paths={LeftArrow} />
        </Button>
        <Text className="scProjectTitle">{projectName}</Text>
      </div>
      <div className="scRightWrapper">
        {(isSu() && projectStatus === ProjectType.ACCEPTED) ? (
          <Button
            type="secondary"
            fluid
            text="Print Project"
            onClick={() => printProjectClick()}
          />
        ) : null}
        {isEdit ? (
          <Button
            type="secondary"
            fluid
            text="Edit project"
            onClick={() => navigateToEditProject()}
          />
        ) : null}
        <Dropdown
          menu={{ items }}
          placement="bottomLeft"
          trigger={['click']}
        >
          <Button
            className="scBtTertiary"
            iconOnly
            small
            type="tertiary"
          >
            <SSRIcon paths={Dots} />
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

const SentAndClosedProjectDetails = () => {
  // scd - SentAndClosedProjectDetails
  const location: any = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { post, fileDownload } = useCallApi();
  const apiErrorMessage = useTranslate('api.failureMessage');
  const [selectedProjectStatus, setSelectedProjectStatus] = useState<ProjectType>();
  const [projectId, setProjectId] = useState<any>(null);
  const [selectedRU, setSelectedRU] = useState<RetailUnit | null>(null);
  const [projectVal, setProjectVal] = useState<any>(null);
  const [projectName, setProjectName] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [iosEndDate, setIosEndDate] = useState<any>(null);
  const [message, setMessage] = useState<any>(null);
  const [additionalMessage, setAdditionalMessage] = useState<any>(null);
  const [items, setItems] = useState<any[]>([]);
  const [activeItems, setActiveItems] = useState<any[]>([]);
  const [ruCount, setRuCount] = useState<any>(0);
  const [retailUnits, setRetailUnits] = useState<any>([]);
  const [sellingUnits, setSellingUnits] = useState<any>([]);
  const [activityLog, setActivityLog] = useState<any>([]);
  const [selectedItemRow, setSelectedItemRow] = useState<Item>();
  const [openItemModal, setOpenItemModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Item>();
  const [projCompCompetitors, setProjCompCompetitors] = useState<any>([]);
  const [selectedItemDetails, setSelectedItemDetails] = useState<Item>();

  const [projectStatusNo, setProjectStatusNo] = useState();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isCopy, setIsCopy] = useState<boolean>(false);
  const [isExport, setIsExport] = useState<boolean>(false);
  const [selectedComp, setSelectedComp] = useState<any[]>([]);
  const [openConnectCompPanel, setOpenConnectCompPanel] = useState<boolean>(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
  const [openProjectNotFoundModal, setOpenProjectNotFoundModal] = useState(false);

  const emptyMessageText = useTranslate('create.proj.emptyMessage');
  const startDateTitle = useTranslate('proj.details.startDate');
  const endDateTitle = useTranslate('proj.details.endDate');
  const IosEndDateTitle = useTranslate('proj.details.IoSEndDate');
  const timeZoneWarningTitle = useTranslate('proj.details.timeZoneWarning');
  const itemSoldWarningTitle = useTranslate('proj.details.itemSoldWarning');
  const messageTitle = useTranslate('create.proj.messageTitle');
  const additionalMessageTitle = useTranslate('create.proj.additionalMessageTitle');
  const projectDeleteError = useTranslate('project.delete.error');
  const projectDeleteSuccess = useTranslate('project.delete.success');
  const projectPrintError = useTranslate('project.print.error');
  const projectExportError = useTranslate('project.export.error');

  const onCloseItemModal = () => {
    setOpenItemModal(false);
  };

  const updateItem = (itemVal: any, isEnabled?: boolean) => {
    const itemsCopy = [...items];
    const index = itemsCopy.findIndex((item: any) => item.itemNo === itemVal.itemNo);
    if (!isNullEmptyOrUndefined(isEnabled)) {
      itemsCopy[index].noCompetition = isEnabled === true ? 1 : 0;
    } else {
      itemsCopy[index].noCompetition = null;
    }
    setItems(JSON.parse(JSON.stringify(itemsCopy)));
  };

  const noCompetitionAPI = (row: any) => {
    dispatch(setLoader(true));
    const rowCopy = JSON.parse(JSON.stringify(row));
    const togglePayload = {
      projectId,
      ItemNo: rowCopy.itemNo,
      projectType: projectType(),
      CurrentUserId: userData()?.userId,
    };
    post('no-competition', togglePayload, (data: any) => {
      const projCompData = data?.projectComparisonCompetitors?.map(
        (val: any) => val.competitorId.toString(),
      );
      setProjCompCompetitors(projCompData || []);
      if (data && data?.isNoCompetition === true) {
        const compData = data?.noCompetitionCompetitors.map(
          (val: any) => val.competitorId.toString(),
        );
        openConnectCompPanel === true ? setSelectedComp([]) : setSelectedComp(compData);
        updateItem(rowCopy, true);
      } else if (data && data?.isNoCompetition === false) {
        updateItem(rowCopy, false);
      } else if (data && data?.isNoCompetition === null) {
        updateItem(rowCopy);
      }
      dispatch(setLoader(false));
    }, () => {
      dispatch(setLoader(false));
    });
  };

  const toggleConnectCompPanel = (row?: any) => {
    setSelectedComp([]);
    noCompetitionAPI(row);
    setSelectedItemRow(row || null);
    setOpenConnectCompPanel(!openConnectCompPanel);
  };

  const setData = (project: any) => {
    if (project) {
      setProjectVal(project);
      if (isIos()) {
        setProjectId(project.gProjectId);
        setProjectName(project.gProjectDetails.GProjectName);
        setStartDate(project.gProjectDetails.projectStartDate);
        setEndDate(project.gProjectDetails.projectEndDate);
        setMessage(project.gProjectDetails.message);
        setItems(project.gProjectItems);
        setRetailUnits(project.gProjectRetailUnits);
        setActivityLog(project.gActivityLogDetails);
        setProjectStatusNo(project.gProjectDetails.statusNo);
        setIsEdit(project.gProjectDetails.isEdit);
        setIsDelete(project.gProjectDetails.isDelete);
        setIsCopy(project.gProjectDetails.isCopy);
        setIsExport(project.gProjectDetails.isExport);
      }
      if (isRu()) {
        setProjectId(project.viewCProjectDetails.RetailUnitProjectId);
        setProjectName(project.viewCProjectDetails.RetailUnitProjectName);
        setStartDate(project.viewCProjectDetails.projectStartDate);
        setEndDate(project.viewCProjectDetails.projectEndDate);
        setIosEndDate(project.viewCProjectDetails.GlobalProjectEndDate);
        setMessage(project.viewCProjectDetails.message);
        setItems(project.viewCProjectItems);
        setActiveItems(project?.viewCProjectItems?.filter((item: any) => item.isActive === true));
        setSellingUnits(project.sellingUnitViewDto);
        setActivityLog(project.rupActivityLogDetails);
        setProjectStatusNo(project.viewCProjectDetails.statusNo);
        setAdditionalMessage(project.viewCProjectDetails.additionalMessage);
        setIsEdit(project.isEdit);
        setIsDelete(project.isDelete);
        setIsCopy(project.isCopy);
        setIsExport(project.isExport);
      }
      if (isSu()) {
        setProjectId(project.sellingUnitProjectBasicViewDto.SellingUnitProjectId);
        setProjectName(project.sellingUnitProjectBasicViewDto.SellingUnitProjectName);
        setStartDate(project.sellingUnitProjectBasicViewDto.projectStartDate);
        setEndDate(project.sellingUnitProjectBasicViewDto.projectEndDate);
        setMessage(project.sellingUnitProjectBasicViewDto.message);
        setAdditionalMessage(project.sellingUnitProjectBasicViewDto.additionalMessage);
        setItems(project.sellingUnitProjectItemViewDto);
        setProjectStatusNo(project.sellingUnitProjectBasicViewDto.statusNo);
        setIsEdit(project.sellingUnitProjectBasicViewDto.isEdit);
        setIsDelete(project.sellingUnitProjectBasicViewDto.isDelete);
        setIsCopy(project.sellingUnitProjectBasicViewDto.isCopy);
        setIsExport(project.sellingUnitProjectBasicViewDto.isExport);
      }
    }
  };

  const getProjectDetails = (data: any) => {
    let projData: any = {};
    if (isIos()) {
      projData = data?.gProjectDetails;
      projData.GProjectId = '';
      projData.projectEndDate = '';
      projData.projectStartDate = dayjs().format('YYYY-MM-DD');
      projData.GProjectName = '';
      return projData;
    }
    if (isRu() && (selectedProjectStatus === ProjectType.ACCEPTED)) {
      projData = data?.viewCProjectDetails;
      projData.RetailUnitProjectId;
      projData.RetailUnitProjectName;
      projData.projectStartDate;
      projData.projectEndDate;
      return projData;
    }
    projData = data?.basicInfo;
    projData.projectId = '';
    projData.projectEndDate = '';
    projData.projectStartDate = dayjs().format('YYYY-MM-DD');
    projData.projectName = '';
    return projData;
  };

  const fetchProjectDetails = ({
    prjtId, type, unitCode,
  }:
    { prjtId: any; type: ProjectType; unitCode: any; }) => {
    dispatch(setLoader(true));
    let endPoint = '';
    let payload = {};
    if (isIos()) {
      endPoint = 'get-global-project';
      payload = {
        gProjectId: prjtId,
        UserId: userData()?.userId,
        ProjectType: projectType(),
        IsCopy: false,
      };
    }
    if (isRu()) {
      endPoint = 'get-ru-project';
      payload = {
        unitCode,
        CurrentUserId: userData()?.userId,
        ParentProjectId: (selectedProjectStatus === ProjectType.DRAFT || isCopy === true)
          ? null : 0,
        projectId: prjtId,
        ProjectType: projectType(),
        IsEditView: false,
        IsCopy: false,
        IsSuperAdmin: userData()?.isSuperAdmin,
      };
    }
    if (isSu() && [ProjectType.ACCEPTED, ProjectType.CLOSED].includes(type)) {
      endPoint = 'get-su-project';
      payload = {
        SellingUnitProjectId: prjtId,
        SuCode: unitCode,
        ProjectType: projectType(),
        CurrentUserId: userData()?.userId,
      };
    }
    post(endPoint, payload, (data: any) => {
      if (data?.isFound !== false) {
        if (isCopy === true) {
          const projData = getProjectDetails(data);
          projData.hasParentProject = false;
          navigate(appRoutes.newProject, {
            state: {
              projData: { ...data, ...projData },
              type: projStatus.draft,
              isCopy: true,
            },
          });
        } else { setData(data); }
      } else {
        setOpenProjectNotFoundModal(true);
      }
      dispatch(setLoader(false));
    }, (err: any) => {
      dispatch(setLoader(false));
      dispatch(setToast({ openToast: true, toastLabel: apiErrorMessage }));
    });
  };

  const reloadProjDetails = () => {
    let endPoint = '';
    let payload = {};
    dispatch(setLoader(true));
    const selectedProj = location?.state?.project;
    const selectedProjStatus = location?.state?.type;
    if (isRu()) {
      endPoint = 'get-ru-project';
      payload = {
        unitCode: selectedRU?.ruCode,
        CurrentUserId: userData()?.userId,
        ParentProjectId: 0,
        projectId: selectedProj?.projectId,
        ProjectType: projectType(),
        IsEditView: false,
        IsCopy: false,
        IsSuperAdmin: userData()?.isSuperAdmin,
      };
    }
    if (isSu() && [ProjectType.ACCEPTED, ProjectType.CLOSED].includes(selectedProjStatus)) {
      endPoint = 'get-su-project';
      payload = {
        SellingUnitProjectId: selectedProj?.projectId,
        SuCode: selectedRU?.ruCode,
        ProjectType: projectType(),
        CurrentUserId: userData()?.userId,
      };
    }
    post(endPoint, payload, (data: any) => {
      if (data?.isFound !== false) {
        setData(data);
      } else {
        setOpenProjectNotFoundModal(true);
      }
      dispatch(setLoader(false));
    }, (err: any) => {
      dispatch(setLoader(false));
      dispatch(setToast({ openToast: true, toastLabel: apiErrorMessage }));
    });
  };

  const onPageLoad = () => {
    const selectedProj = location?.state?.project;
    const selectedProjStatus = location?.state?.type;
    setSelectedProjectStatus(selectedProjStatus);
    offlineData.getItem('retailUnits').then((ruList: any) => {
      ruList?.length > 0 && setRuCount(ruList.length);
    });
    offlineData.getItem('selectedRetailUnit').then((data: any) => {
      setSelectedRU(data);
      fetchProjectDetails({
        prjtId: selectedProj?.projectId,
        type: selectedProjStatus,
        unitCode: data?.ruCode,
      });
    });
  };

  useEffect(() => {
    onPageLoad();
  }, []);

  const isItemClickable = () => (isRu() && selectedProjectStatus === ProjectType.SENT)
    || (isSu() && selectedProjectStatus === ProjectType.ACCEPTED);

  const onItemClick = (item: Item) => {
    if (isItemClickable() === true) {
      dispatch(setLoader(true));
      setSelectedItemDetails(item);
      const payload = {
        ProjectId: projectId,
        UnitCode: selectedRU?.ruCode,
        UserId: userData()?.userId,
        ItemNo: item.itemNo,
        ProjectType: projectType(),
        ItemState: item.state,
        ItemType: item.itemType,
      };
      post('comparisonitem-information', payload, (data: any) => {
        setSelectedItem(data);
        setOpenItemModal(true);
        dispatch(setLoader(false));
      }, () => {
        dispatch(setLoader(false));
      });
    }
  };

  const navigateToFC = (val: any) => {
    window.open(`${appRoutes.FC}?item=${val?.itemNo}&id=${projectId}`, '_blank', 'noreferrer');
  };

  const renderPaNo = (text: any, value: any) => {
    const isActive = value?.isActive;
    return (
      !isActive && isRu() ? (
        <div className="table-number removeItem">
          {value.isHighLighted && <SSRIcon className={styles.noticeIcon} paths={Notice} /> }
          {text}
        </div>
      ) : (
        <div
          className="table-number"
          tabIndex={0}
          role="button"
        >
          {value.isHighLighted && <SSRIcon className={styles.noticeIcon} paths={Notice} /> }
          {text}
        </div>
      )
    );
  };

  const checkItemClick = (value: any) => {
    const isActive = value?.isActive;
    if (isActive || !isRu()) {
      onItemClick(value);
    }
  };

  const onEditClick = (row: any) => {
    if (openConnectCompPanel === false) {
      noCompetitionAPI(row);
    }
    setOpenConnectCompPanel(!openConnectCompPanel);
    setSelectedItemRow(row || null);
  };

  const renderItemName = (text: any, value: any) => {
    const isActive = value?.isActive;
    return (
      <div
        className="scTableNameContainer"
        tabIndex={0}
        role="button"
      >
        {!isActive && isRu() ? (
          <div className="scTableCellWrapper removeItem">{text}</div>
        ) : (
          <div
            className="scTableCellWrapper"
          >
            {text}
          </div>
        )}
        {value?.isBti === true ? (
          <BtiComponent />
        ) : null}
      </div>
    );
  };

  const renderItemType = (text: any, value: any) => {
    const isActive = value?.isActive;
    return (
      !isActive && isRu() ? (
        <div className="removeItem">{text}</div>
      ) : (
        <div
          tabIndex={0}
          role="button"
        >
          {text}
        </div>
      )
    );
  };

  const renderItem = (text: any, value: any) => {
    const isActive = value?.isActive;
    return (
      !isActive && isRu() ? (
        <div className="table-number removeItem itemNo-common-design">{text}</div>
      ) : (
        <div
          className="table-number itemNo-common-design"
          tabIndex={0}
          role="button"
        >
          {text}
        </div>
      )
    );
  };

  const renderNoCompetition = (row: any) => {
    if (row['noCompetition'] === null || row['noCompetition'] === 0) {
      if (row['isNoCompetitionEnabled'] === true) {
        return (
          <img
            className="scCb"
            alt="cbUnchecked"
            src={cbUnchecked}
            tabIndex={row['_itemNo']}
            role="button"
            onKeyDown={(e) => {
              e.stopPropagation();
              selectedProjectStatus !== ProjectType.CLOSED && toggleConnectCompPanel(row);
            }}
            onClick={(e) => {
              e.stopPropagation();
              selectedProjectStatus !== ProjectType.CLOSED && toggleConnectCompPanel(row);
            }}
          />
        );
      }
      return (
        <img className="scCb scCbDisable" alt="cbUncheckedDisabled" src={cbUncheckedDisabled} />
      );
    }
    return (
      <img className="scCb scCbDisable" alt="cbChecked" src={cbChecked} />
    );
  };

  const renderComparison = (text: any, row: any) => {
    const isActive = row?.isActive;
    return (
      !isActive && isRu() ? (
        null
      ) : (
        <div className="scTableCell">
          <div className="scCountWrapper">
            <Text className="scComparisonCount">
              {row['comparisonCount']}
            </Text>
            {row['comparisonCount'] > 0 ? (
              <SSRIcon
                className="scLinkOut"
                paths={LinkOut}
                onClick={(e) => {
                  e.stopPropagation();
                  navigateToFC(row);
                }}
              />
            ) : <div className="scLinkOut scDisabled" />}
          </div>
          <div className="scTableNC">
            <div id={row['_itemNo']} className="scTableNCText">
              {renderNoCompetition(row)}
              No competition
            </div>
            {row['noCompetition'] === 1 ? (
              <SSRIcon
                onClick={(e) => {
                  e.stopPropagation();
                  onEditClick(row);
                }}
                className="scEditNC"
                paths={IcPencil}
              />
            ) : null}
          </div>
          {isItemClickable() ? (
            <SSRIcon
              paths={RightArrow}
              tabIndex={0}
              role="button"
            />
          ) : null}
        </div>
      )
    );
  };

  const columns: ColumnsType<Item> = [
    {
      key: 1,
      title: useTranslate('tab.items.paNo'),
      dataIndex: 'paNo',
      width: '8%',
      className: 'table-number',
      render: (text: any, value: Item) => renderPaNo(text, value),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 2,
      title: useTranslate('tab.items.item_name'),
      dataIndex: 'itemName',
      width: isIos() ? '57.5%' : '40%',
      render: (text: any, value: Item) => renderItemName(text, value),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 3,
      title: useTranslate('tab.items.type'),
      dataIndex: 'itemType',
      width: isIos() ? '11.5%' : '10%',
      className: 'table-header-padding',
      render: (text: any, value: Item) => renderItemType(text, value),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 4,
      title: useTranslate('tab.items.item'),
      dataIndex: 'itemNo',
      width: isIos() ? '22.5%' : '15%',
      className: 'table-number',
      render: (text: any, value: Item) => renderItem(text, value),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
    {
      key: 5,
      title: 'Comparisons',
      dataIndex: ['_itemNo', 'comparisonCount', 'noCompetition'],
      width: isIos() ? '30%' : '35%',
      render: (text: any, row: any) => renderComparison(text, row),
      shouldCellUpdate: (record: any, prevRecord: any) => record !== prevRecord,
    },
  ];

  const highlightedValExists = () => items.some((item: any) => item.isHighLighted === true);

  const printProjectClick = () => {
    dispatch(setLoader(true));
    const payload = {
      ProjectName: projectName,
      Message: isNullEmptyOrUndefined(message) ? '' : convertToBase64(message),
      AdditionalMessage: isNullEmptyOrUndefined(additionalMessage) ? '' : convertToBase64(additionalMessage),
      projectType: projectType(),
      ProjectStartDate: startDate,
      ProjectEndDate: projectType() === 1 ? iosEndDate || endDate : endDate,
      unitCode: selectedRU?.ruCode,
      projectId,
      MessagePrefix: isRu() ? 'RU' : 'SU',
    };
    fileDownload('generate-pdf', payload, (response: any) => {
      jsdownload(response, `${projectName}.pdf`);
      dispatch(setLoader(false));
    }, () => {
      dispatch(setLoader(false));
      dispatch(setToast({ openToast: true, toastLabel: projectPrintError }));
    });
  };

  const onDeleteProject = () => {
    dispatch(setLoader(true));
    const endPoint = 'project-delete';
    const payload = {
      ProjectId: projectId,
      ProjectType: projectType(),
      StatusNo: projectStatusNo,
    };
    post(endPoint, payload, (data: any) => {
      dispatch(setToast({
        openToast: true,
        toastLabel: projectDeleteSuccess,
      }));
      navigate(
        appRoutes.dashboard,
        {
          state: {
            dashboardFilters: true,
          },
        },
      );
      dispatch(setLoader(false));
    }, () => {
      dispatch(setLoader(false));
      dispatch(setToast({ openToast: true, toastLabel: projectDeleteError }));
    });
  };

  const onExportProject = () => {
    dispatch(setLoader(true));
    let endPoint = '';
    let payload = {};
    if (isIos()) {
      endPoint = 'export-ios-item';
      payload = {
        gProjectId: projectId,
        UserId: userData()?.userId,
        IsCopy: false,
        IsExport: true,
      };
    }
    if (isRu()) {
      endPoint = 'export-ru-item';
      payload = {
        ParentProjectId: 0,
        unitCode: selectedRU?.ruCode,
        projectId,
        CurrentUserId: userData()?.userId,
        IsSuperAdmin: userData()?.isSuperAdmin,
        IsExport: true,
      };
    }
    if (isSu()) {
      endPoint = 'export-su-item';
      payload = {
        SellingUnitProjectId: projectId,
        SuCode: selectedRU?.ruCode,
      };
    }
    fileDownload(endPoint, payload, (response: any) => {
      jsdownload(response, `${projectName}.xlsx`);
      dispatch(setLoader(false));
    }, () => {
      dispatch(setLoader(false));
      dispatch(setToast({ openToast: true, toastLabel: projectExportError }));
    });
  };

  const navigateToEditProject = () => {
    projectVal.isCopy = false;
    navigate(appRoutes.newProject, { state: { projData: projectVal, isEditProject: true } });
  };

  return (
    <div className="scContainer">
      <Header
        isEdit={isEdit}
        isDelete={isDelete}
        isCopy={isCopy}
        isExport={isExport}
        projectName={projectName}
        onBackPress={() => navigate(
          appRoutes.dashboard,
          {
            state: {
              dashboardFilters: true,
            },
          },
        )}
        printProjectClick={() => printProjectClick()}
        onDeleteProject={() => setOpenDeleteConfirmationModal(true)}
        onExportProject={() => onExportProject()}
        onCopyProject={() => fetchProjectDetails(
          {
            prjtId: projectId,
            type: ProjectType.DRAFT,
            unitCode: selectedRU?.ruCode || '',
          },
        )}
        projectStatus={selectedProjectStatus}
        navigateToEditProject={() => navigateToEditProject()}
      />
      <div className="scContentWrapper">
        <div className="scDateContainer">
          {startDate ? (
            <div className="scDateWrapper">
              <Text className="scTitle">{startDateTitle}</Text>
              <Text className="scBody">{startDate}</Text>
            </div>
          ) : null}
          {endDate ? (
            <div className="scDateWrapper">
              <Text className="scTitle">{endDateTitle}</Text>
              <Text className="scBody">{endDate}</Text>
            </div>
          ) : null}
          {iosEndDate ? (
            <div className="scDateWrapper">
              <Text className="scTitle">{IosEndDateTitle}</Text>
              <Text className="scBody">{iosEndDate}</Text>
            </div>
          ) : null}
        </div>
        <div className="scWarningWrapper">
          <SSRIcon className="scWarningBlue" paths={Notice} />
          <Text className="scWarning">{timeZoneWarningTitle}</Text>
        </div>
        <div className="scMessageWrapper">
          <Text className="scTitle">{messageTitle}</Text>
          <Text className="scBody">{message || emptyMessageText}</Text>
        </div>
        {((isRu() && (selectedProjectStatus === ProjectType.CLOSED
        || selectedProjectStatus === ProjectType.SENT))
        || (isSu() && selectedProjectStatus === ProjectType.ACCEPTED)) ? (
          <div className="scMessageWrapper">
            <Text className="scTitle">{additionalMessageTitle}</Text>
            <Text className="scBody">{additionalMessage || emptyMessageText}</Text>
          </div>
          ) : null}
        {!isSu() ? (
          <IncludedUnits
            units={isIos() ? retailUnits : sellingUnits}
            activityLog={activityLog}
            unitCount={isIos() ? ruCount : sellingUnits.length}
            selectedProjectStatus={selectedProjectStatus}
          />
        ) : null}
        <div className="scItemsSectionWrapper">
          <Text className="scTitle">{`Items (${isRu() ? activeItems?.length : items?.length})`}</Text>
          {highlightedValExists() ? (
            <div className="scWarningWrapper">
              <SSRIcon className="scWarningOrange" paths={Notice} />
              <Text className="scWarning">{itemSoldWarningTitle}</Text>
            </div>
          ) : null}
          {items?.length > 0 ? (
            <Table
              className={`table-striped-rows ${isItemClickable() === true ? 'scTableClickable' : 'scTableNormal'}`}
              columns={isIos() ? columns.slice(0, -1) : columns}
              dataSource={items}
              pagination={false}
              onRow={(record) => ({
                onClick: () => checkItemClick(record),
              })}
              size="middle"
              rowKey={(record: any) => record.itemNo}
              scroll={items?.length > 0 ? { y: 'calc(100vh - 280px)' } : { y: '' }}
              footer={undefined}
            />
          ) : null}
        </div>
      </div>
      {!isNullEmptyOrUndefined(selectedItem) ? (
        <ItemDetails
          projectId={projectId}
          openItemModal={openItemModal}
          selectedItem={selectedItem}
          onCloseItemModal={onCloseItemModal}
          selectedProj={location?.state?.project}
          selectedItemData={selectedItemDetails}
        />
      ) : null}
      <CloseModal
        modelOpen={openDeleteConfirmationModal}
        closeModal={(type) => {
          type === 2 ? onDeleteProject() : setOpenDeleteConfirmationModal(false);
        }}
        saveValid
        closeText={useTranslate('proj.details.delete.confirmation.negetive')}
        saveText={useTranslate('proj.details.delete.confirmation.positive')}
        title={useTranslate('proj.details.delete.confirmation.title')}
        subText={useTranslate('proj.details.delete.confirmation.content')}
      />
      <CloseModal
        modelOpen={openProjectNotFoundModal}
        closeModal={() => {
          navigate(appRoutes.dashboard);
        }}
        saveValid
        closeText={useTranslate('dashboard.projNotFound.action')}
        saveText=""
        title={useTranslate('dashboard.projNotFound.title')}
        subText={useTranslate('dashboard.projNotFound.text')}
      />
      <ConnectCompetitorPanel
        isVisible={openConnectCompPanel}
        selectedItemData={selectedItemRow}
        selectedCompetitors={selectedComp}
        onClose={toggleConnectCompPanel}
        updateItem={updateItem}
        projectId={projectId}
        selectedRU={selectedRU}
        selectedProjCompetitors={projCompCompetitors}
        onPageReLoad={reloadProjDetails}
      />
    </div>
  );
};

export default SentAndClosedProjectDetails;
