import React from 'react';
import './BtiComponent.css';

const BtiComponent = ({ ml, large }: {ml?: boolean, large?: boolean}) => (
  <div
    className={`icBti
        ${large === true ? 'icBtiLarge' : ''}
        ${ml === true ? 'icBtiMl' : 'icBtiNoMl'}`}
  >
    BTI
  </div>
);

BtiComponent.defaultProps = {
  ml: false,
  large: false,
};

export default BtiComponent;
